import React, { useState } from "react"
import { navigate } from "gatsby"
import { useMutation } from "@apollo/react-hooks"
import { SIGN_IN } from "./../gql"
import Throbber from "./../components/throbber"
import { formatServerError } from "../utilities/helpers"
import LayoutContainer from "../components/layout"
import brandLogo from "./../assets/brands/te/logo.png"
import { APP_ROUTES } from "../constants"
import { Trans, withTranslation, useTranslation } from "react-i18next"
import useCurrentLang from "../hooks/useCurrentLang"
import api from "../utilities/api"

const Login = ({ location }) => {
  const { code: langCode } = useCurrentLang()
  const [emailOrUserName, setEmailOrUserName] = useState("")
  const [password, setPassword] = useState("")
  const [rememberUser, setRememberUser] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [signIn, { loading, error }] = useMutation(SIGN_IN)
  const { t } = useTranslation()

  React.useEffect(() => {
    if (
      localStorage.getItem("auth_token") ||
      sessionStorage.getItem("auth_token")
    ) {
      const token =
        localStorage.getItem("auth_token") ||
        sessionStorage.getItem("auth_token")
      api.setAuthorizationXToken(token)
      // User is already logged in, send them to home
      navigate(APP_ROUTES.intro)
    }
  }, [])

  const handleRememberUser = event => {
    const { checked } = event.currentTarget
    setRememberUser(checked)
  }

  const handleAcceptTerms = event => {
    const { checked } = event.currentTarget
    setTermsAccepted(checked)
  }

  const onSubmit = event => {
    event.preventDefault()
    signIn({
      variables: {
        emailOrUserName,
        password,
      },
      update: setAuthToken,
    })
  }

  const setAuthToken = (
    cache,
    {
      data: {
        signIn: { token, changePassword },
      },
    }
  ) => {
    cache.writeData({
      data: {
        authToken: token,
        changePassword: changePassword,
      },
    })

    api.setAuthorizationXToken(token)

    localStorage.setItem("change_password", changePassword)
    if (rememberUser) {
      localStorage.setItem("auth_token", token)
    } else {
      sessionStorage.setItem("auth_token", token)
    }

    if (changePassword) {
      navigate("/choose-password")
    } else {
      navigate(APP_ROUTES.intro)
    }
  }

  return (
    <LayoutContainer seoTitle="Login" path={location.pathname}>
      <div className="row">
        <div className="col l8 offset-l2 s12">
          <div className="center-align margin--bottom-40">
            <h1 className="big-title uppercase no-margin">
              <Trans i18nKey={"login.login"} />
            </h1>
          </div>
        </div>
        <div className="col l4 offset-l4 m8 offset-m2 s12">
          <form className="form" onSubmit={onSubmit} noValidate>
            <div className="form__image-wrapper">
              <img className="form__top-image" src={brandLogo} alt="Login" />
            </div>
            <label className="sr-only" htmlFor="email">
              <Trans i18nKey={"login.email_label"} />
            </label>
            <input
              id="emailOrUserName"
              type="text"
              placeholder={t("login.email_placeholder")}
              className="form__text-input"
              onChange={e => setEmailOrUserName(e.target.value)}
              value={emailOrUserName}
            />
            <label className="sr-only" htmlFor="password">
              <Trans i18nKey={"login.password_label"} />
            </label>
            <input
              id="password"
              type="password"
              placeholder={t("login.password_label")}
              className="form__text-input"
              onChange={e => setPassword(e.target.value)}
              value={password}
            />
            <div className="form__actions">
              <div style={{ width: "100%" }}>
                <div className="form__checkbox-wrapper margin--bottom-8 form__remember-me-wrapper">
                  <div>
                    <input
                      className="form__checkbox-input"
                      type="checkbox"
                      id="remember-user"
                      onChange={handleRememberUser}
                    />
                    <label
                      className="form__checkbox-label small-text"
                      htmlFor="remember-user"
                    >
                      <Trans i18nKey={"login.remember_label"} />
                    </label>
                  </div>
                  <a
                    className="form__reset-password small-text"
                    href={t("login.forgot_password_href")}
                  >
                    <Trans i18nKey={"login.forgot_password__label"} />
                  </a>
                </div>
                <div>
                  <input
                    className="form__checkbox-input"
                    type="checkbox"
                    id="terms"
                    onChange={handleAcceptTerms}
                  />
                  <label
                    className="form__checkbox-label small-text"
                    htmlFor="terms"
                  >
                    <div className="form__checkbox-wrapper">
                      <span>
                        {" "}
                        <Trans i18nKey={"login.terms"} />{" "}
                      </span>
                      <a
                        className="link--small"
                        href={`https://s3.eu-central-1.amazonaws.com/static-content.1change.co/MD_Term/Terms_${
                          langCode === "zh" ? "HK" : langCode.toUpperCase()
                        }.html`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Trans i18nKey={"login.terms_text_link"} />
                      </a>
                      <span>, </span>
                      <a
                        className="link--small"
                        href={`https://s3.eu-central-1.amazonaws.com/static-content.1change.co/MD_Privacy/Policy_${
                          langCode === "zh" ? "HK" : langCode.toUpperCase()
                        }.html`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Trans i18nKey={"login.privay_policy"} />
                      </a>
                      <span>
                        {" "}
                        <Trans i18nKey={"login.terms_text_and"} />{" "}
                      </span>
                      <a
                        className="link--small"
                        href={`https://s3.eu-central-1.amazonaws.com/static-content.1change.co/MD_Cookie/Cookie_${
                          langCode === "zh" ? "HK" : langCode.toUpperCase()
                        }.html`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Trans i18nKey={"login.cookie_policy"} />
                      </a>
                      {langCode === "de" || langCode === "ja" ? (
                        <Trans i18nKey={"login.zu"} />
                      ) : null}
                    </div>
                  </label>
                </div>
                {/* <div className="reset-password">
                  <a
                    className="form__reset-password small-text cursor-pointer"
                    onClick={() => {
                      navigate(APP_ROUTES.signUp)
                    }}
                  >
                    <Trans i18nKey={"login.register_link"} />
                  </a>
                </div> */}
              </div>
            </div>
            <div className="center-align">
              <button
                type="submit"
                className="button"
                disabled={!termsAccepted}
              >
                <Trans i18nKey={"login.login"} />
              </button>
            </div>
            <div className="center-align">
              {loading && <Throbber loadingText="Signing in..." relative />}
              {error && <p>{formatServerError(error.message)}</p>}
            </div>
          </form>
        </div>
      </div>
    </LayoutContainer>
  )
}

export default withTranslation()(Login)
